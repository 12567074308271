@import '~@csstools/normalize.css';

// Color overrides
$black: #10161a;

$dark-gray1: #182026;
$dark-gray2: #202b33;
$dark-gray3: #293742;
$dark-gray4: #30404d;
$dark-gray5: #394b59;

$gray1: #5c7080;
$gray2: #738694;
$gray3: #a5a5a5; // changed
$gray4: #a7b6c2;
$gray5: #bfccd6;

$light-gray1: #bbbbbb; // changed
$light-gray2: #c5c5c5; // changed
$light-gray3: #dbdbdb; // changed
$light-gray4: #ebebeb; // changed
$light-gray5: #f1f1f1; // changed

$white: #ffffff;

$blue1: #470a95; // changed
$blue2: #6917d4; // changed
$blue3: #8539e8; // changed
$blue4: #9a5deb; // changed
$blue5: #ad7ee9; // changed

$green1: #0a6640;
$green2: #0d8050;
$green3: #0f9960;
$green4: #15b371;
$green5: #3dcc91;

$orange1: #a66321;
$orange2: #bf7326;
$orange3: #d9822b;
$orange4: #f29d49;
$orange5: #ffb366;

$red1: #a82a2a;
$red2: #c23030;
$red3: #db3737;
$red4: #f55656;
$red5: #ff7373;

// Typography overrides
$headings: (
  'h1': (
    50px,
    54px,
  ),
  'h2': (
    44px,
    48px,
  ),
  'h3': (
    32px,
    36px,
  ),
  'h4': (
    18px,
    21px,
  ),
  'h5': (
    16px,
    19px,
  ),
  'h6': (
    14px,
    16px,
  ),
);

$pt-grid-size: 12px;
$pt-font-size: 16px;

$pt-text-color: $dark-gray5;
$pt-text-color-muted: $gray2;

$pt-dark-text-color: $light-gray1;
$pt-dark-text-color-muted: $gray2;

$pt-font-family: 'Fira Sans', sans-serif;
$pt-heading-font-family: 'Nova Mono', monospace;

@import '~@blueprintjs/core/src/blueprint.scss';

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap');

:root {
  --primary-color: #{$pt-intent-primary};
  --primary-color-components: #{red($pt-intent-primary)},
    #{green($pt-intent-primary)}, #{blue($pt-intent-primary)};

  --font-family: #{$pt-font-family};
}

body {
  margin: 0;
  overflow-y: scroll;
}

html,
body,
#app {
  min-height: 100vh;
}

#app {
  --background-color: #{$light-gray5};
  --text-color: #{$pt-text-color};
  --text-color-muted: #{$pt-text-color-muted};

  background-color: var(--background-color);

  .#{$ns}-dark & {
    --background-color: #{$dark-gray2};
    --text-color: #{$pt-dark-text-color};
    --text-color-muted: #{$pt-dark-text-color-muted};
  }
}

:focus {
  outline-offset: 4px;
}

.bp4-tooltip .bp4-popover-content {
  max-width: 30rem;
}

.bp4-popover:not(.bp4-tooltip) .bp4-popover-content {
  max-width: 30rem;
  padding: 1rem;
}

.bp4-toast-container-inline {
  position: fixed !important;
}

a:not(.#{$ns}-menu-item) {
  text-decoration: underline;
}

h1.#{$ns}-heading,
h2.#{$ns}-heading,
h3.#{$ns}-heading {
  font-family: $pt-heading-font-family;
  font-weight: normal;
}

h1.#{$ns}-heading,
h2.#{$ns}-heading {
  color: $pt-intent-primary;

  .#{$ns}-dark & {
    color: $blue4;
  }
}

section {
  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

section > h3 {
  padding-bottom: 10px !important;
  border-bottom: 2px solid $light-gray3;
  margin-bottom: 20px !important;

  .#{$ns}-dark & {
    border-bottom: 2px solid $dark-gray3;
  }
}
